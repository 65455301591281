import React from 'react';
import Navbar from './Navbar';
import './collection.css';

const CollectionBlouse = () => (
    <div>
        <Navbar/>
        <div>
        <a href="#blouse1">
                 <img src="/blouse1.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse1">
                <img src="/blouse1.jpg" alt="Plein écran"/>
            </a>
        <a href="#blouse2">
                 <img src="/blouse2.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse2">
                <img src="/blouse2.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse3">
                 <img src="/blouse3.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse3">
                <img src="/blouse3.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse4">
                 <img src="/blouse4.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse4">
                <img src="/blouse4.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse5">
                 <img src="/blouse5.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse5">
                <img src="/blouse5.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse6">
                 <img src="/blouse6.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse6">
                <img src="/blouse6.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse7">
                 <img src="/blouse7.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse7">
                <img src="/blouse7.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse8">
                 <img src="/blouse8.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse8">
                <img src="/blouse8.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse9">
                 <img src="/blouse9.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse9">
                <img src="/blouse9.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse10">
                 <img src="/blouse10.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse10">
                <img src="/blouse10.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse11">
                 <img src="/blouse11.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse11">
                <img src="/blouse11.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse12">
                 <img src="/blouse12.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse12">
                <img src="/blouse12.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse13">
                 <img src="/blouse13.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse13">
                <img src="/blouse13.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse14">
                 <img src="/blouse14.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse14">
                <img src="/blouse14.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse15">
                 <img src="/blouse15.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse15">
                <img src="/blouse15.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse16">
                 <img src="/blouse16.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse16">
                <img src="/blouse16.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse17">
                 <img src="/blouse17.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse17">
                <img src="/blouse17.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse18">
                 <img src="/blouse18.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse18">
                <img src="/blouse18.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse19">
                 <img src="/blouse19.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse19">
                <img src="/blouse19.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse20">
                 <img src="/blouse20.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse20">
                <img src="/blouse20.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse21">
                 <img src="/blouse21.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse21">
                <img src="/blouse21.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse22">
                 <img src="/blouse22.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse22">
                <img src="/blouse22.jpg" alt="Plein écran"/>
            </a>
            <a href="#blouse23">
                 <img src="/blouse23.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="blouse23">
                <img src="/blouse23.jpg" alt="Plein écran"/>
            </a>
        </div>
    </div>
)

export default CollectionBlouse;