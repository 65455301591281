import React from 'react';
import Navbar from './Navbar';
import './collection.css';

const CollectionMasque = () => (
    <div>
        <Navbar/>
        <div>
        <a href="#masque1">
                 <img src="/masque1.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque1">
                <img src="/masque1.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque28">
                 <img src="/masque28.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque28">
                <img src="/masque28.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque26">
                 <img src="/masque26.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque26">
                <img src="/masque26.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque11">
                 <img src="/masque11.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque11">
                <img src="/masque11.jpg" alt="Plein écran"/>
            </a>
            
            <a href="#masque13">
                 <img src="/masque13.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque13">
                <img src="/masque13.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque36">
                 <img src="/masque36.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque36">
                <img src="/masque36.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque30">
                 <img src="/masque30.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque30">
                <img src="/masque30.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque31">
                 <img src="/masque31.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque31">
                <img src="/masque31.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque32">
                 <img src="/masque32.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque32">
                <img src="/masque32.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque34">
                 <img src="/masque34.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque34">
                <img src="/masque34.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque33">
                 <img src="/masque33.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque33">
                <img src="/masque33.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque3">
                 <img src="/masque3.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#masque12">
                 <img src="/masque12.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque12">
                <img src="/masque12.jpg" alt="Plein écran"/>
            </a>
           
            <a href="#_" class="overlay" id="masque3">
                <img src="/masque3.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque17">
                 <img src="/masque17.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque17">
                <img src="/masque17.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque4">
                 <img src="/masque4.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque4">
                <img src="/masque4.jpg" alt="Plein écran"/>
            </a>
              <a href="#masque15">
                 <img src="/masque15.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque15">
                <img src="/masque15.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque19">
                 <img src="/masque19.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque19">
                <img src="/masque19.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque20">
                 <img src="/masque20.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque20">
                <img src="/masque20.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque21">
                 <img src="/masque21.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque21">
                <img src="/masque21.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque22">
                 <img src="/masque22.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque22">
                <img src="/masque22.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque5">
                 <img src="/masque5.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
        
            <a href="#_" class="overlay" id="masque5">
                <img src="/masque5.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque6">
                 <img src="/masque6.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque6">
                <img src="/masque6.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque7">
                 <img src="/masque7.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque7">
                <img src="/masque7.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque28">
                 <img src="/masque28.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque28">
                <img src="/masque28.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque8">
                 <img src="/masque8.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque8">
                <img src="/masque8.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque9">
                 <img src="/masque9.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque9">
                <img src="/masque9.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque23">
                 <img src="/masque23.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque23">
                <img src="/masque23.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque10">
                 <img src="/masque10.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque10">
                <img src="/masque10.jpg" alt="Plein écran"/>
            </a>
           
            <a href="#masque14">
                 <img src="/masque14.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque14">
                <img src="/masque14.jpg" alt="Plein écran"/>
            </a>
          
            <a href="#masque16">
                 <img src="/masque16.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque16">
                <img src="/masque16.jpg" alt="Plein écran"/>
            </a>
           
            <a href="#masque18">
                 <img src="/masque18.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque18">
                <img src="/masque18.jpg" alt="Plein écran"/>
            </a>
           
            
            <a href="#masque24">
                 <img src="/masque24.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque24">
                <img src="/masque24.jpg" alt="Plein écran"/>
            </a>
         
           
            <a href="#masque13">
                 <img src="/masque27.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque27">
                <img src="/masque27.jpg" alt="Plein écran"/>
            </a>
           
            <a href="#masque29">
                 <img src="/masque29.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque29">
                <img src="/masque29.jpg" alt="Plein écran"/>
            </a>
            <a href="#masque2">
                 <img src="/masque2.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque2">
                <img src="/masque2.jpg" alt="Plein écran"/>
            </a>
           
            <a href="#masque25">
                 <img src="/masque25.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque25">
                <img src="/masque25.jpg" alt="Plein écran"/>
            </a>
         
            <a href="#masque35">
                 <img src="/masque35.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque35">
                <img src="/masque35.jpg" alt="Plein écran"/>
            </a>
         
            <a href="#masque37">
                 <img src="/masque37.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="masque37">
                <img src="/masque37.jpg" alt="Plein écran"/>
            </a>
        </div>    </div>
)

export default CollectionMasque;