import React from 'react';
import ReactLoading from 'react-loading';
import './acceuil.css';

const Loading = () => (
    
    <div className='load'>
            <ReactLoading 
                className='loading' 
                type='bars'
                color='#D6AF26'
             /> 
        
    </div>
)
export default Loading