import React from 'react';
import Navbar from './Navbar';
import './collection.css'

const CollectionWomen = () => (
    <div>
        <Navbar/>
        <div>
            <a href="#femme1">
            <img src="/femme1.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme1">
                <img src="/femme1.jpg" alt="Plein écran"/>
                <img src="/femme1-2.jpg" alt="Plein écran"/>
            </a>

            <a href="#femme2">
                 <img src="/femme2.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme2">
                <img src="/femme2.jpg" alt="Plein écran"/>
                <img src="/femme2-2.jpg" alt="Plein écran"/>
            </a>

            <a href="#femme3">
                 <img src="/femme3-2.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme3">
                <img src="/femme3.jpg" alt="Plein écran"/>
                <img src="/femme3-2.jpg" alt="Plein écran"/>
            </a>

            <a href="#femme4">
                 <img src="/femme4.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme4">
                <img src="/femme4.jpg" alt="Plein écran"/>
            </a>

            <a href="#femme5">
                 <img src="/femme5.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme5">
                <img src="/femme5.jpg" alt="Plein écran"/>
                <img src="/femme5-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme6">
                 <img src="/femme6.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme6">
                <img src="/femme6.jpg" alt="Plein écran"/>
                <img src="/femme6-2.jpg" alt="Plein écran"/>
            </a>  
            <a href="#femme7">
                 <img src="/femme7.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme7">
                <img src="/femme7.jpg" alt="Plein écran"/>
                <img src="/femme7-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme8">
                 <img src="/femme8.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme8">
                <img src="/femme8.jpg" alt="Plein écran"/>
                <img src="/femme8-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme9">
                 <img src="/femme9-3.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme9">
                <img src="/femme9-3.jpg" alt="Plein écran"/>
                <img src="/femme9-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme10">
                 <img src="/femme10.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme10">
                <img src="/femme10.jpg" alt="Plein écran"/>
                <img src="/femme10-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme11">
                 <img src="/femme11.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme11">
                <img src="/femme11.jpg" alt="Plein écran"/>
                <img src="/femme11-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme12">
                 <img src="/femme12.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme12">
                <img src="/femme12.jpg" alt="Plein écran"/>
                <img src="/femme27.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme14">
                 <img src="/femme14.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme14">
                <img src="/femme14.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme15">
                 <img src="/femme15.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme15">
                <img src="/femme15.jpg" alt="Plein écran"/>
                <img src="/femme15-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme16">
                 <img src="/femme16.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme16">
                <img src="/femme16.jpg" alt="Plein écran"/>
                <img src="/femme16-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme17">
                 <img src="/femme17.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme17">
                <img src="/femme17.jpg" alt="Plein écran"/>
                <img src="/femme17-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme18">
                 <img src="/femme18.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme18">
                <img src="/femme18.jpg" alt="Plein écran"/>
                <img src="/femme18-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme19">
                 <img src="/femme19.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme19">
                <img src="/femme19.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme20">
                 <img src="/femme20.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme20">
                <img src="/femme20.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme21">
                 <img src="/femme21.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme21">
                <img src="/femme21.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme22">
                 <img src="/femme22.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme22">
                <img src="/femme22.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme23">
                 <img src="/femme23.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay" id="femme23">
                <img src="/femme23.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme24">
                 <img src="/femme24.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme24">
                <img src="/femme24.jpg" alt="Plein écran"/>
                <img src="/femme24-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme25">
                 <img src="/femme25.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme25">
                <img src="/femme25.jpg" alt="Plein écran"/>
                <img src="/femme25-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme26">
                 <img src="/femme26.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme26">
                <img src="/femme26.jpg" alt="Plein écran"/>
                <img src="/femme26-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme27">
                 <img src="/femme43-2.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme27">
                <img src="/femme43.jpg" alt="Plein écran"/>
                <img src="/femme43-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme28">
                 <img src="/femme44.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme28">
                <img src="/femme44.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme29">
                 <img src="/femme45-2.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme29">
                <img src="/femme45.jpg" alt="Plein écran"/>
                <img src="/femme45-3.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme30">
                 <img src="/femme46.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme30">
                <img src="/femme46.jpg" alt="Plein écran"/>
                <img src="/femme46-2.jpg" alt="Plein écran"/>
            </a>
            <a href="#femme31">
                 <img src="/femme47.jpg" alt="Vignette" className='picCollectionWomen'/>
            </a>
            <a href="#_" class="overlay2" id="femme31">
                <img src="/femme47.jpg" alt="Plein écran"/>
                <img src="/femme47-2.jpg" alt="Plein écran"/>
            </a>
        </div>
    </div>
)

export default CollectionWomen;